
















import { Component, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'
import DatePickerBase from '@/components/atoms/DatePickerBase.vue'
import moment from 'moment'

@Component({
  components: {
    ButtonBase,
    RadioGroup,
    DatePickerBase,
  },
})
export default class CsvDownload extends Vue {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  private from = ''
  private to = ''

  private csvTypeRadioItems = [
    { label: '目標達成状況', value: 1 },
    { label: '学習状況', value: 2 },
    { label: '理解度', value: 3 },
  ]
  private csvTypeValue = 1

  private isProcessing = false

  private get colortype(): string {
    return this.from && this.to ? 'blue' : 'pointer-events-none'
  }

  private async downloadCsv() {
    const diff = moment(this.to, 'YYYY-MM-DD').diff(moment(this.from, 'YYYY-MM-DD'), 'months')
    if (diff >= 3) {
      alert('期間指定は3ヶ月以内としてください。')
      return
    }

    if (this.isProcessing) return
    Vue.prototype.$loading.start()
    this.isProcessing = true

    let apiPath = ''
    switch (this.csvTypeValue) {
      case 1:
        apiPath = `${this.isV3 ? '/v3' : ''}/studentGoals/csvOutput`
        break
      case 2:
        apiPath = '/history/resultSummariesCsv'
        break
      case 3:
        apiPath = '/history/resultDrillsCsv'
        break
    }
    const params = { branchId: this.branchId, from: this.from, to: this.to }
    await Vue.prototype.$http.httpWithToken
      .get(apiPath, {
        responseType: 'blob',
        dataType: 'binary',
        headers: {
          Accept: 'application/csv',
        },
        params: params,
      })
      .then((res: any) => {
        const blob = new Blob([res.data], { type: 'application/csv' })
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = this.getFilename(res.headers['content-disposition'])
        a.click()
      })
      .catch(() => {
        alert('エラーが発生しました')
      })
      .finally(() => {
        this.isProcessing = false
        Vue.prototype.$loading.complete()
      })
  }

  /**
   * Content-Dispositionヘッダーからfilenameを取得する
   */
  private getFilename(contentDisposition: string): string {
    const filenameRegex = /filename\*=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/
    const matches = filenameRegex.exec(contentDisposition)
    if (matches != null && matches[1]) {
      return decodeURI(matches[1].replace(/['"]/g, '').replace('utf-8', ''))
    } else {
      alert('ファイル名の取得に失敗しました')
      return 'file_name_error.csv'
    }
  }
}
